// JsFromRoutes CacheKey e1804da9487a540bb2573cb399ec7756
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@consumer/services/api'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/mp'),
  show: /* #__PURE__ */ definePathHelper('get', '/mp/:slug'),
  create: /* #__PURE__ */ definePathHelper('post', '/mp'),
  draft: /* #__PURE__ */ definePathHelper('get', '/mp/draft/:internal_slug'),
  update: /* #__PURE__ */ definePathHelper('patch', '/mp/draft/:internal_slug'),
  updatePlaces: /* #__PURE__ */ definePathHelper('put', '/mp/draft/:internal_slug/places'),
  titleRecommendation: /* #__PURE__ */ definePathHelper('get', '/mp/draft/:internal_slug/title_recommendation'),
  guidebookRecommendation: /* #__PURE__ */ definePathHelper('get', '/mp/draft/:internal_slug/guidebook_recommendation'),
  descriptionRecommendation: /* #__PURE__ */ definePathHelper('get', '/mp/draft/:internal_slug/description_recommendation'),
  finishEditing: /* #__PURE__ */ definePathHelper('get', '/mp/draft/:internal_slug/finish_editing'),
}
